/**
 * Styles.css
 *
 * Created by Dennis Gecaj on 9/20/19
 *
 * @desc The stylesheet for the project card.
 * @author Dennis Gecaj
 * @copyright © Dennis Gecaj. All rights reserved.
 * @version 1.0
 */

.SkillsCardComponent.CardTag {
    padding: 25px 25px 25px 25px;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: 100%;
    border-radius: 10px;
}

@media only screen and (min-width: 720px) {
    .SkillsCardComponent.CardTag {
        padding: 50px 50px 50px 50px;
    }
}

.SkillsCardComponent.TitleTag {
    margin: 0;
    padding: 0;
    height: 40px;
}

@media only screen and (min-width: 720px) {
    .SkillsCardComponent.TitleTag {
        height: 60px;
    }
}

.SkillsCardComponent.HashTag {
    margin: 10px 10px 0 0;
    display: inline-block;
    padding: 4px 10px;
    border-radius: 20px;
}

.SkillsCardComponent.IconTag {
    height: 30px;
    position: absolute;
    right: 25px;
    top: 25px;
    opacity: 0.4;
}

@media only screen and (min-width: 720px) {
    .SkillsCardComponent.IconTag {
        height: 40px;
        right: 50px;
        top: 50px;
    }
}

