.FocusbaseTag {
    height: 680px;
}

@media only screen and (min-width: 540px) {
    .YelloTag {
        height: 780px;
    }
}

@media only screen and (min-width: 680px) {
    .FocusbaseTag {
        height: 940px;
    }
}

@media only screen and (min-width: 1040px) {
    .FocusbaseTag {
        height: 760px;
    }
}

@media only screen and (min-width: 1120px) {
    .FocusbaseTag {
        height: 800px;
    }
}

.YelloTag {
    height: 660px;
}

@media only screen and (min-width: 540px) {
    .YelloTag {
        height: 780px;
    }
}

@media only screen and (min-width: 700px) {
    .YelloTag {
        height: 1000px;
    }
}

@media only screen and (min-width: 840px) {
    .YelloTag {
        height: 1200px;
    }
}

@media only screen and (min-width: 1040px) {
    .YelloTag {
        height: 800px;
    }
}

@media only screen and (min-width: 1120px) {
    .YelloTag {
        height: 920px;
    }
}

.UserlaneTag {
    height: 620px;
}

@media only screen and (min-width: 540px) {
    .UserlaneTag {
        height: 780px;
    }
}

@media only screen and (min-width: 680px) {
    .UserlaneTag {
        height: 980px;
    }
}

@media only screen and (min-width: 1040px) {
    .UserlaneTag {
        height: 700px;
    }
}

@media only screen and (min-width: 1120px) {
    .UserlaneTag {
        height: 780px;
    }
}

.RideSharingAppTag {
    height: 560px;
}

@media only screen and (min-width: 540px) {
    .RideSharingAppTag {
        height: 640px;
    }
}

@media only screen and (min-width: 680px) {
    .RideSharingAppTag {
        height: 820px;
    }
}

@media only screen and (min-width: 1040px) {
    .RideSharingAppTag {
        height: 640px;
    }
}

@media only screen and (min-width: 1120px) {
    .RideSharingAppTag {
        height: 700px;
    }
}

.AiderlyTag {
    height: 540px;
}

@media only screen and (min-width: 540px) {
    .AiderlyTag {
        height: 660px;
    }
}

@media only screen and (min-width: 680px) {
    .AiderlyTag {
        height: 820px;
    }
}

@media only screen and (min-width: 1040px) {
    .AiderlyTag {
        height: 640px;
    }
}

@media only screen and (min-width: 1120px) {
    .AiderlyTag {
        height: 700px;
    }
}

.CoffeeCollectionTag {
    height: 780px;
}

@media only screen and (min-width: 540px) {
    .CoffeeCollectionTag {
        height: 980px;
    }
}

@media only screen and (min-width: 680px) {
    .CoffeeCollectionTag {
        height: 1300px;
    }
}

@media only screen and (min-width: 1040px) {
    .CoffeeCollectionTag {
        height: 900px;
    }
}

@media only screen and (min-width: 1120px) {
    .CoffeeCollectionTag {
        height: 1000px;
    }
}

.LacosteTag {
    height: 620px;
}

@media only screen and (min-width: 540px) {
    .LacosteTag {
        height: 780px;
    }
}

@media only screen and (min-width: 680px) {
    .LacosteTag {
        height: 1000px;
    }
}

@media only screen and (min-width: 1040px) {
    .LacosteTag {
        height: 700px;
    }
}

@media only screen and (min-width: 1120px) {
    .LacosteTag {
        height: 800px;
    }
}
