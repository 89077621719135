.ContactCardComponent.CardTag {
    padding: 50px 25px 25px 25px;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: 100%;
    background-color: #F5F5F5;
    border-radius: 10px;
    height: 10px;
}

@media only screen and (min-width: 720px) {
    .ContactCardComponent.CardTag {
        padding: 50px 50px 50px 50px;
        height: 50px;
    }
}

.ContactCardComponent.SocialTag {
    bottom: 25px;
    right: 25px;
    position: absolute;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: flex-start;
    list-style-position: inside;
    line-height: normal;
    flex-direction: row;
    float: right;
}

@media only screen and (min-width: 720px) {
    .ContactCardComponent.SocialTag {
        bottom: 50px;
        right: 50px;
    }
}

.ContactCardComponent.ActionTag {
    left: 25px;
    bottom: 25px;
    position: absolute;
}

@media only screen and (min-width: 720px) {
    .ContactCardComponent.ActionTag {
        left: 50px;
        bottom: 50px;
    }
}
