.ScreenCardComponent.CardTag {
    padding: 0 25px 25px 25px;
    margin: 0;
    border-radius: 10px;
}

@media only screen and (min-width: 720px) {
    .ScreenCardComponent.CardTag {
        padding: 0 50px 50px 50px;
        margin: 0;
    }
}

.ScreenCardComponent.ImageTag {
    width: 100%;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 100%;
    height: calc(100% - 150px);
}

@media only screen and (min-width: 720px) {
    .ScreenCardComponent.ImageTag {
        height: calc(100% - 200px);
    }
}

.ScreenCardComponent.TitleTag {
    text-align: center;
}

.ScreenCardComponent.LogoTag {
    padding-top: 50px;
    display: block;
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (min-width: 720px) {
    .ScreenCardComponent.LogoTag {
        padding-top: 100px;
        width: 60px;
        height: 60px;
    }
}

.ScreenCardComponent.ContentTag {
    position: absolute;
    bottom: 25px;
    left: 25px;
    right: 25px;
}

@media only screen and (min-width: 720px) {
    .ScreenCardComponent.ContentTag {
        bottom: 50px;
        left: 50px;
        right: 50px;
    }
}

