/**
 * Styles.css
 *
 * Created by Dennis Gecaj on 12/28/17
 *
 * @desc The stylesheet for all button components.
 * @author Dennis Gecaj
 * @copyright © Yello Lab Inc. All rights reserved.
 * @version 1.0
 */

.ActionButtonComponent.ButtonTag {
    margin: auto;
    padding: 6px 15px;
    height: 30px;
    border-radius: 50vh;
    display: block;
    text-align: center;
    border-style: none;
    transition: 300ms;
    outline: none;
    color: #FFFFFF;
    cursor: pointer;
}

@media only screen and (min-width: 720px) {
    .ActionButtonComponent.ButtonTag {
        padding: 8px 20px;
        height: 40px;
    }
}

.ActionButtonComponent.ButtonTag.Loading {
    color: transparent;
    box-shadow: none;
}

.ActionButtonComponent.ButtonTag.Error {
    color: transparent;
    box-shadow: none;
}

.ActionButtonComponent.ButtonTag.Enabled:hover {
    opacity: 1;
}

.ActionButtonComponent.ButtonTag.Enabled:active {
    opacity: 1;
}

.ActionButtonComponent.ButtonTag:hover {
    -webkit-transform: scale(1.04);
    -ms-transform: scale(1.04);
    transform: scale(1.04);
}

.ActionButtonComponent.ButtonTag:active {
    -webkit-transform: scale(0.96);
    -ms-transform: scale(0.96);
    transform: scale(0.96);
}

.ActionButtonComponent.IconTag {
    margin: 0;
    padding: 0 5px 0 0;
    position: relative;
    top: 2px;
    width: 19px;
    height: 19px;
}

.ActionButtonComponent.ImageTag {
    padding: 0;
    position: relative;
    top: 2px;
    right: calc(50% - 9.5px);
    width: 19px;
    height: 19px;
}

.SocialButtonComponent {
    margin: 0 0 0 25px;
    padding: 0;
    background-color: transparent;
    display: inline-block;
    border-style: none;
    transition: 300ms;
    outline: none;
}

.SocialButtonComponent:hover {
    opacity: .5;
}

.SocialButtonComponent.ImageTag {
    margin: 0;
    padding: 0;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

@media only screen and (min-width: 720px) {
    .SocialButtonComponent.ImageTag {
        width: 40px;
        height: 40px;
    }
}
