.FooterCardComponent.CardTag {
    padding: 50px 25px 25px 25px;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: 100%;
    background-color: #F5F5F5;
    border-radius: 10px;
    height: 20px;
}

@media only screen and (min-width: 540px) {
    .FooterCardComponent.CardTag {
        height: 20px;
    }
}

@media only screen and (min-width: 680px) {
    .FooterCardComponent.CardTag {
        height: 80px;
    }
}

@media only screen and (min-width: 1040px) {
    .FooterCardComponent.CardTag {
        padding: 50px 50px 50px 50px;
        height: 120px;
    }
}

@media only screen and (min-width: 1120px) {
    .FooterCardComponent.CardTag {
        height: 80px;
    }
}


.FooterCardComponent.LogoTag {
    height: 19px;
    margin: 8px 5px -4px 5px;
}

.FooterCardComponent.CopyrightTag {
    padding-top: 5px;
    opacity: 0.4;
}

.FooterCardComponent.FooterTextTag {
    left: 25px;
    bottom: 25px;
    position: absolute;
}

@media only screen and (min-width: 720px) {
    .FooterCardComponent.FooterTextTag {
        left: 50px;
        bottom: 50px;
    }
}
