/**
 * Styles.css
 *
 * Created by Dennis Gecaj on 9/20/19
 *
 * @desc The stylesheet for the project card.
 * @author Dennis Gecaj
 * @copyright © Dennis Gecaj. All rights reserved.
 * @version 1.0
 */

.ExperienceCardComponent.CardTag {
    padding: 25px 25px 25px 25px;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: 100%;
    border-radius: 10px;
}

@media only screen and (min-width: 720px) {
    .ExperienceCardComponent.CardTag {
        padding: 50px 50px 50px 50px;
    }
}

.ExperienceCardComponent.TitleTag {
    margin: 0;
    padding: 0;
    height: 30px;
}

@media only screen and (min-width: 720px) {
    .ExperienceCardComponent.TitleTag {
        height: 40px;
    }
}

.ExperienceCardComponent.SubtitleTag {
    margin: 5px 0 10px 0;
    padding: 0;
    opacity: 0.5;
}

.ExperienceCardComponent.HashTag {
    margin: 10px 10px 0 0;
    display: inline-block;
    padding: 4px 10px;
    border-radius: 20px;
}

.ExperienceCardComponent.BodyTag {
    margin: 20px 0 20px 0;
    padding: 0;
}

.ExperienceCardComponent.LogoTag {
    height: 30px;
    position: absolute;
    right: 25px;
    top: 25px;
}

@media only screen and (min-width: 720px) {
    .ExperienceCardComponent.LogoTag {
        height: 40px;
        right: 50px;
        top: 50px;
    }
}
