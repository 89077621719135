.AboutHeroCardComponent.CardTag {
    padding: 50px 25px 25px 25px;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: 100%;
    background-color: #F5F5F5;
    border-radius: 10px;
    height: 400px;
}

@media only screen and (min-width: 720px) {
    .AboutHeroCardComponent.CardTag {
        padding: 100px 50px 50px 50px;
        height: 445px;
    }
}

.AboutHeroCardComponent.ActionTag {
    left: 25px;
    bottom: 25px;
    position: absolute;
}

@media only screen and (min-width: 720px) {
    .AboutHeroCardComponent.ActionTag {
        left: 50px;
        bottom: 50px;
    }
}

.AboutHeroCardComponent.MarkerTag {
    font-style: normal;
    border-radius: 0.8em 0.1em 0.8em 0.1em;
    background-image:
            linear-gradient(-2deg,
            rgba(255,214,0,0.1),
            rgba(255,214,0,0.7) 100%,
            rgba(255,214,0,0.2)
            );
}
