.TextCardComponent.CardTag {
    padding: 50px 25px 25px 25px;
    border-radius: 10px;
}

@media only screen and (min-width: 720px) {
    .TextCardComponent.CardTag {
        padding: 100px 50px 50px 50px;
    }
}

.TextCardComponent.ContentTag {
    position: absolute;
    bottom: 25px;
    left: 25px;
    right: 25px;
}

@media only screen and (min-width: 720px) {
    .TextCardComponent.ContentTag {
        bottom: 50px;
        left: 50px;
        right: 50px;
    }
}

.TextCardComponent.YellowMarkerTag {
    font-style: normal;
    border-radius: 1em 0.2em 1em 0.2em;
    background-image:
            linear-gradient(-2deg,
            rgba(255,214,0,0.1),
            rgba(255,214,0,0.7) 100%,
            rgba(255,214,0,0.2)
            );
}

.TextCardComponent.BlueMarkerTag {
    font-style: normal;
    border-radius: 1em 0.2em 1em 0.2em;
    background-image:
            linear-gradient(-2deg,
            rgba(46,62,77,0.1),
            rgba(46,62,77,0.4) 100%,
            rgba(46,62,77,0.2)
            );
}

.TextCardComponent.GreenMarkerTag {
    font-style: normal;
    border-radius: 1em 0.2em 1em 0.2em;
    background-image:
            linear-gradient(-2deg,
            rgba(46,77,66,0.1),
            rgba(46,77,66,0.4) 100%,
            rgba(46,77,66,0.2)
            );
}

.TextCardComponent.BrownMarkerTag {
    font-style: normal;
    border-radius: 1em 0.2em 1em 0.2em;
    background-image:
            linear-gradient(-2deg,
            rgba(77,67,46,0.1),
            rgba(77,67,46,0.4) 100%,
            rgba(77,67,46,0.2)
            );
}

.TextCardComponent.WhiteMarkerTag {
    font-style: normal;
    border-radius: 1em 0.2em 1em 0.2em;
    background-image:
            linear-gradient(-2deg,
            rgba(255,255,255,0.1),
            rgba(255,255,255,0.4) 100%,
            rgba(255,255,255,0.2)
            );
}
