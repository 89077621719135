.FocusbaseCaseStudyContainer.HeroTag {
    height: 340px;
    background-size: 100%;
}

@media only screen and (min-width: 540px) {
    .FocusbaseCaseStudyContainer.HeroTag {
        height: 460px;
        background-size: 100%;
    }
}

@media only screen and (min-width: 700px) {
    .FocusbaseCaseStudyContainer.HeroTag {
        height: 500px;
        background-size: 100%;
    }
}

@media only screen and (min-width: 840px) {
    .FocusbaseCaseStudyContainer.HeroTag {
        height: 660px;
        background-size: 100%;
    }
}

@media only screen and (min-width: 1020px) {
    .FocusbaseCaseStudyContainer.HeroTag {
        height: 550px;
        background-size: 60%;
    }
}

.FocusbaseCaseStudyContainer.IntroductionTag {
    height: 160px;
}

.FocusbaseCaseStudyContainer.ImageTag {
    height: 300px;
}

@media only screen and (min-width: 720px) {
    .FocusbaseCaseStudyContainer.ImageTag {
        height: 500px;
    }
}

.FocusbaseCaseStudyContainer.ObjectTag {
    height: 400px;
}

.FocusbaseCaseStudyContainer.SessionsTag {
    height: 720px;
}

@media only screen and (min-width: 540px) {
    .FocusbaseCaseStudyContainer.SessionsTag {
        height: 880px;
    }
}

@media only screen and (min-width: 680px) {
    .FocusbaseCaseStudyContainer.SessionsTag {
        height: 1080px;
    }
}

@media only screen and (min-width: 880px) {
    .FocusbaseCaseStudyContainer.SessionsTag {
        height: 1180px;
    }
}

@media only screen and (min-width: 980px) {
    .FocusbaseCaseStudyContainer.SessionsTag {
        height: 1280px;
    }
}

@media only screen and (min-width: 1040px) {
    .FocusbaseCaseStudyContainer.SessionsTag {
        height: 900px;
    }
}

@media only screen and (min-width: 1120px) {
    .FocusbaseCaseStudyContainer.SessionsTag {
        height: 1040px;
    }
}

.FocusbaseCaseStudyContainer.ReflectionTag {
    height: 680px;
}

@media only screen and (min-width: 540px) {
    .FocusbaseCaseStudyContainer.ReflectionTag {
        height: 840px;
    }
}

@media only screen and (min-width: 680px) {
    .FocusbaseCaseStudyContainer.ReflectionTag {
        height: 1040px;
    }
}

@media only screen and (min-width: 880px) {
    .FocusbaseCaseStudyContainer.ReflectionTag {
        height: 1140px;
    }
}

@media only screen and (min-width: 980px) {
    .FocusbaseCaseStudyContainer.ReflectionTag {
        height: 1240px;
    }
}

@media only screen and (min-width: 1040px) {
    .FocusbaseCaseStudyContainer.ReflectionTag {
        height: 860px;
    }
}

@media only screen and (min-width: 1120px) {
    .FocusbaseCaseStudyContainer.ReflectionTag {
        height: 1000px;
    }
}

.FocusbaseCaseStudyContainer.FocusGoalTag {
    height: 680px;
}

@media only screen and (min-width: 540px) {
    .FocusbaseCaseStudyContainer.FocusGoalTag {
        height: 840px;
    }
}

@media only screen and (min-width: 680px) {
    .FocusbaseCaseStudyContainer.FocusGoalTag {
        height: 1240px;
    }
}

@media only screen and (min-width: 880px) {
    .FocusbaseCaseStudyContainer.FocusGoalTag {
        height: 860px;
    }
}

@media only screen and (min-width: 980px) {
    .FocusbaseCaseStudyContainer.FocusGoalTag {
        height: 1000px;
    }
}

@media only screen and (min-width: 1040px) {
    .FocusbaseCaseStudyContainer.FocusGoalTag {
        height: 860px;
    }
}

@media only screen and (min-width: 1120px) {
    .FocusbaseCaseStudyContainer.FocusGoalTag {
        height: 1000px;
    }
}

.FocusbaseCaseStudyContainer.StatisticsTag {
    height: 680px;
}

@media only screen and (min-width: 540px) {
    .FocusbaseCaseStudyContainer.StatisticsTag {
        height: 840px;
    }
}

@media only screen and (min-width: 680px) {
    .FocusbaseCaseStudyContainer.StatisticsTag {
        height: 1040px;
    }
}

@media only screen and (min-width: 880px) {
    .FocusbaseCaseStudyContainer.StatisticsTag {
        height: 1140px;
    }
}

@media only screen and (min-width: 980px) {
    .FocusbaseCaseStudyContainer.StatisticsTag {
        height: 1240px;
    }
}

@media only screen and (min-width: 1040px) {
    .FocusbaseCaseStudyContainer.StatisticsTag {
        height: 860px;
    }
}

@media only screen and (min-width: 1120px) {
    .FocusbaseCaseStudyContainer.StatisticsTag {
        height: 1000px;
    }
}

.FocusbaseCaseStudyContainer.OfficeInternImageTag {
    height: 300px;
}

@media only screen and (min-width: 720px) {
    .FocusbaseCaseStudyContainer.OfficeInternImageTag {
        height: 400px;
    }
}

.FocusbaseCaseStudyContainer.FieldResearchTag {
    height: 400px;
}

.FocusbaseCaseStudyContainer.InterviewUsersImageTag {
    height: 260px;
}

@media only screen and (min-width: 720px) {
    .FocusbaseCaseStudyContainer.InterviewUsersImageTag {
        height: 400px;
    }
}

.FocusbaseCaseStudyContainer.InterviewTextTag {
    height: 400px;
}

@media only screen and (min-width: 720px) {
    .FocusbaseCaseStudyContainer.InterviewTextTag {
        height: 520px;
    }
}

.FocusbaseCaseStudyContainer.ConceptTextTag {
    height: 400px;
}

@media only screen and (min-width: 720px) {
    .FocusbaseCaseStudyContainer.ConceptTextTag {
        height: 445px;
    }
}

.FocusbaseCaseStudyContainer.ConceptImageTag {
    height: 395px;
}

@media only screen and (min-width: 720px) {
    .FocusbaseCaseStudyContainer.ConceptImageTag {
        height: 595px;
    }
}

.FocusbaseCaseStudyContainer.TestingTag {
    height: 485px;
}

@media only screen and (min-width: 720px) {
    .FocusbaseCaseStudyContainer.TestingTag {
        height: 565px;
    }
}

.FocusbaseCaseStudyContainer.FinalTag {
    height: 420px;
}

@media only screen and (min-width: 720px) {
    .FocusbaseCaseStudyContainer.FinalTag {
        height: 445px;
    }
}

.FocusbaseCaseStudyContainer.QuoteTag {
    height: 465px;
}

@media only screen and (min-width: 720px) {
    .FocusbaseCaseStudyContainer.QuoteTag {
        height: 565px;
    }
}


