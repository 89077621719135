.AboutContainer.IntroductionTag {
    height: 480px;
}

@media only screen and (min-width: 720px) {
    .AboutContainer.IntroductionTag {
        height: 595px;
    }
}

.AboutContainer.ProfileImageTag {
    height: 420px;
}

@media only screen and (min-width: 720px) {
    .AboutContainer.ProfileImageTag {
        height: 595px;
    }
}

.AboutContainer.SkillsTextTag {
    height: 260px;
}

@media only screen and (min-width: 720px) {
    .AboutContainer.SkillsTextTag {
        height: 420px;
    }
}

.AboutContainer.DesignSkillsCard {
    height: 250px;
}

@media only screen and (min-width: 720px) {
    .AboutContainer.DesignSkillsCard {
        height: 200px;
    }
}

.AboutContainer.SoftwareSkillsCard {
    height: 140px;
}

@media only screen and (min-width: 720px) {
    .AboutContainer.SoftwareSkillsCard {
        height: 120px;
    }
}

.AboutContainer.EducationTextTag {
    height: 325px;
}

@media only screen and (min-width: 720px) {
    .AboutContainer.EducationTextTag {
        height: 325px;
    }
}
