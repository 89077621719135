.CoffeeCollectionCaseStudyContainer.HeroTag {
    height: 200px;
    background-size: 100%;
}

@media only screen and (min-width: 540px) {
    .CoffeeCollectionCaseStudyContainer.HeroTag {
        height: 260px;
        background-size: 100%;
    }
}

@media only screen and (min-width: 700px) {
    .CoffeeCollectionCaseStudyContainer.HeroTag {
        height: 300px;
        background-size: 100%;
    }
}

@media only screen and (min-width: 840px) {
    .CoffeeCollectionCaseStudyContainer.HeroTag {
        height: 400px;
        background-size: 100%;
    }
}

@media only screen and (min-width: 1020px) {
    .CoffeeCollectionCaseStudyContainer.HeroTag {
        height: 550px;
        background-size: 100%;
    }
}

.CoffeeCollectionCaseStudyContainer.IntroductionTag {
    height: 300px;
}


@media only screen and (min-width: 720px) {
    .CoffeeCollectionCaseStudyContainer.IntroductionTag {
        height: 240px;
    }
}

.CoffeeCollectionCaseStudyContainer.FrontIllustrationImageTag {
    height: 420px;
}

@media only screen and (min-width: 720px) {
    .CoffeeCollectionCaseStudyContainer.FrontIllustrationImageTag {
        height: 740px;
    }
}

.CoffeeCollectionCaseStudyContainer.SideIllustrationImageTag {
    height: 160px;
}

@media only screen and (min-width: 720px) {
    .CoffeeCollectionCaseStudyContainer.SideIllustrationImageTag {
        height: 300px;
    }
}

.CoffeeCollectionCaseStudyContainer.ExplorationIllustrationImageTag {
    height: 480px;
}

@media only screen and (min-width: 720px) {
    .CoffeeCollectionCaseStudyContainer.ExplorationIllustrationImageTag {
        height: 850px;
    }
}

.CoffeeCollectionCaseStudyContainer.ExplorationTextTag {
    height: 300px;
}

@media only screen and (min-width: 720px) {
    .CoffeeCollectionCaseStudyContainer.ExplorationTextTag {
        height: 470px;
    }
}

.CoffeeCollectionCaseStudyContainer.BuildingTextTag {
    height: 530px;
}

@media only screen and (min-width: 720px) {
    .CoffeeCollectionCaseStudyContainer.BuildingTextTag {
        height: 565px;
    }
}
