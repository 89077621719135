.QuoteCardComponent.CardTag {
    padding: 50px 25px 25px 25px;
    border-radius: 10px;
}

@media only screen and (min-width: 720px) {
    .QuoteCardComponent.CardTag {
        padding: 100px 50px 50px 50px;
    }
}

.QuoteCardComponent.HeaderTag {
    position: absolute;
    top: 25px;
    left: 25px;
    right: 25px;
}

@media only screen and (min-width: 720px) {
    .QuoteCardComponent.HeaderTag {
        top: 50px;
        left: 50px;
        right: 50px;
    }
}

.QuoteCardComponent.ContentTag {
    position: absolute;
    bottom: 25px;
    left: 25px;
    right: 25px;
}

@media only screen and (min-width: 720px) {
    .QuoteCardComponent.ContentTag {
        bottom: 50px;
        left: 50px;
        right: 50px;
    }
}

.QuoteCardComponent.QuotationTag {
    margin: -20px 0 0 0;
    height: 80px;
}

@media only screen and (min-width: 720px) {
    .QuoteCardComponent.QuotationTag {
        margin: -40px 0 0 0;
        height: 100px;
    }
}

.QuoteCardComponent.BlueMarkerTag {
    font-style: normal;
    border-radius: 1em 0.2em 1em 0.2em;
    background-image:
            linear-gradient(-2deg,
            rgba(46,62,77,0.1),
            rgba(46,62,77,0.4) 100%,
            rgba(46,62,77,0.2)
            );
}
