/**
 * Styles.css
 *
 * Created by Dennis Gecaj on 9/20/19
 *
 * @desc The stylesheet for the project card.
 * @author Dennis Gecaj
 * @copyright © Dennis Gecaj. All rights reserved.
 * @version 1.0
 */

.ProjectCardComponent.CardTag {
    padding: 25px 25px 25px 25px;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: 100%;
    border-radius: 10px;
}

@media only screen and (min-width: 720px) {
    .ProjectCardComponent.CardTag {
        padding: 50px 50px 50px 50px;
    }
}

.ProjectCardComponent.TitleTag {
    margin: 0;
    padding: 0;
    height: 30px;
}

@media only screen and (min-width: 720px) {
    .ProjectCardComponent.TitleTag {
        height: 40px;
    }
}

.ProjectCardComponent.SubtitleTag {
    margin: 5px 0 10px 0;
    padding: 0;
    opacity: 0.5;
}

.ProjectCardComponent.HashTag {
    margin: 10px 10px 0 0;
    display: inline-block;
    padding: 4px 10px;
    border-radius: 20px;
}

.ProjectCardComponent.BodyTag {
    margin: 20px 0 20px 0;
    padding: 0;
}

.ProjectCardComponent.LogoTag {
    height: 30px;
    position: absolute;
    right: 25px;
    top: 25px;
}

@media only screen and (min-width: 720px) {
    .ProjectCardComponent.LogoTag {
        height: 40px;
        right: 50px;
        top: 50px;
    }
}

.ProjectCardComponent.ImageTag {
    width: calc(100% + 100px);
    position: absolute;
    margin: -100px -50px;
}

.ProjectCardComponent.InfoTag {
    padding-bottom: 5px;
}

.ProjectCardComponent.OrganizationTag {
    padding-top: 2px;
    left: 120px;
    position: absolute;
}

@media only screen and (min-width: 720px) {
    .ProjectCardComponent.OrganizationTag {
        left: 180px;
    }
}

.ProjectCardComponent.RoleTag {
    padding-top: 2px;
    left: 120px;
    position: absolute;
}

@media only screen and (min-width: 720px) {
    .ProjectCardComponent.RoleTag {
        left: 180px;
    }
}

.ProjectCardComponent.ActionsTag {
    left: 25px;
    bottom: 25px;
    position: absolute;
    flex-direction: row;
    float: right;
    display: flex;
    align-items: flex-start;
    list-style-position: inside;
    width: 100%;
}

@media only screen and (min-width: 720px) {
    .ProjectCardComponent.ActionsTag {
        left: 50px;
        bottom: 50px;
    }
}

.ProjectCardComponent.ActionTag {
    margin: 0 10px 0 0;
}
