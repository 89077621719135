.ExplanationCardComponent.CardTag {
    padding: 25px 0 0 25px;
    height: 45px;
    border-radius: 10px;
}

@media only screen and (min-width: 1040px) {
    .ExplanationCardComponent.CardTag {
        padding: 25px 0 0 25px;
    }
}
