/**
 * Styles.css
 *
 * Created by Dennis Gecaj on 9/20/19
 *
 * @desc The stylesheet for all wrapper components.
 * @author Dennis Gecaj
 * @copyright © Dennis Gecaj. All rights reserved.
 * @version 1.0
 */

.AllWrapperComponent {
    margin: 0;
    padding: 0;
    overflow: hidden;
    max-width: 100%;
    min-width: 280px;
    min-height: 100vh;
}

.BodyWrapperComponent {
    margin: 0;
    padding: 0;
    max-width: 100%;
    min-width: 280px;
    min-height: calc(100vh - 344px);
}

.CenterWrapperComponent {
    margin: auto;
    padding: 25px 25px 0 25px;
    overflow: visible;
    max-width: 1200px;
    min-width: 280px;
}

@media only screen and (min-width: 420px) {
    .CenterWrapperComponent {
        padding: 50px 50px 25px 50px;
    }
}

@media only screen and (min-width: 720px) {
    .CenterWrapperComponent {
        padding: 100px 100px 50px 100px;
    }
}

.ColumnWrapperComponent {
    margin-bottom: 25px;
}

@media only screen and (min-width: 720px) {
    .ColumnWrapperComponent {
        margin-bottom: 50px;
    }
}

.FullWrapperComponent {
    margin: 0;
    padding: 0;
    overflow: visible;
    max-width: 100%;
    min-width: 280px;
}
