/**
 * Styles.css
 *
 * Created by Dennis Gecaj on 9/20/19
 *
 * @desc The stylesheet for the project card.
 * @author Dennis Gecaj
 * @copyright © Dennis Gecaj. All rights reserved.
 * @version 1.0
 */

.HeroCardComponent.CardTag {
    position: relative;
    padding: 50px 25px 25px 25px;
    margin-bottom: 25px;
    background-position: bottom right;
    background-repeat: no-repeat;
    border-radius: 10px;
}

@media only screen and (min-width: 720px) {
    .HeroCardComponent.CardTag {
        padding: 100px 50px 50px 50px;
        margin-bottom: 50px;
    }
}

.HeroCardComponent.TitleTag {
    margin: 0;
    padding: 0;
    height: 36px;
}

@media only screen and (min-width: 720px) {
    .HeroCardComponent.TitleTag {
        height: 50px;
    }
}

.HeroCardComponent.SubtitleTag {
    margin: 5px 0 10px 0;
    padding: 0;
    opacity: 0.5;
}

.HeroCardComponent.HashTag {
    margin: 10px 10px 0 0;
    padding: 4px 10px;
    border-radius: 20px;
    display: none;
}

@media only screen and (min-width: 1020px) {
    .HeroCardComponent.HashTag {
        display: inline-block;
    }
}

.HeroCardComponent.BodyTag {
    margin: 20px 0 20px 0;
    padding: 0;
    display: none;
}

@media only screen and (min-width: 1020px) {
    .HeroCardComponent.BodyTag {
        display: block;
    }
}

.HeroCardComponent.LogoTag {
    height: 30px;
    position: absolute;
    right: 25px;
    top: 50px;
}

@media only screen and (min-width: 720px) {
    .HeroCardComponent.LogoTag {
        height: 40px;
        right: 50px;
        top: 100px;
    }
}

.HeroCardComponent.ImageTag {
    width: calc(100% + 100px);
    position: absolute;
    margin: -100px -50px;
}

.HeroCardComponent.InfoTag {
    padding-bottom: 5px;
    display: none;
}

@media only screen and (min-width: 1020px) {
    .HeroCardComponent.InfoTag {
        display: block;
    }
}

.HeroCardComponent.OrganizationTag {
    padding-top: 2px;
    left: 120px;
    position: absolute;
    display: none;
}

@media only screen and (min-width: 720px) {
    .HeroCardComponent.OrganizationTag {
        left: 180px;
    }
}

@media only screen and (min-width: 1020px) {
    .HeroCardComponent.OrganizationTag {
        display: inline-block;
    }
}

.HeroCardComponent.RoleTag {
    padding-top: 2px;
    left: 120px;
    position: absolute;
    display: none;
}

@media only screen and (min-width: 720px) {
    .HeroCardComponent.RoleTag {
        left: 180px;
    }
}

@media only screen and (min-width: 1020px) {
    .HeroCardComponent.RoleTag {
        display: inline-block;
    }
}

.HeroCardComponent.ActionsTag {
    left: 25px;
    bottom: 25px;
    position: absolute;
    flex-direction: row;
    float: right;
    display: flex;
    align-items: flex-start;
    list-style-position: inside;
    width: 100%;
}

@media only screen and (min-width: 720px) {
    .HeroCardComponent.ActionsTag {
        left: 50px;
        bottom: 50px;
    }
}

.HeroCardComponent.ActionTag {
    margin: 0 10px 0 0;
}

.HeroCardComponent.DetailsTag {
    width: 100%;
    height: 100%;
}

@media only screen and (min-width: 720px) {
    .HeroCardComponent.DetailsTag {
        width: 40%;
    }
}


