.YelloCaseStudyContainer.HeroTag {
    height: 360px;
    background-size: 100%;
}

@media only screen and (min-width: 540px) {
    .YelloCaseStudyContainer.HeroTag {
        height: 580px;
        background-size: 100%;
    }
}

@media only screen and (min-width: 700px) {
    .YelloCaseStudyContainer.HeroTag {
        height: 600px;
        background-size: 100%;
    }
}

@media only screen and (min-width: 840px) {
    .YelloCaseStudyContainer.HeroTag {
        height: 760px;
        background-size: 100%;
    }
}

@media only screen and (min-width: 1020px) {
    .YelloCaseStudyContainer.HeroTag {
        height: 550px;
        background-size: 58%;
    }
}

.YelloCaseStudyContainer.IntroductionTag {
    height: 300px;
}


.YelloCaseStudyContainer.FriendsTag {
    height: 720px;
}

@media only screen and (min-width: 540px) {
    .YelloCaseStudyContainer.FriendsTag {
        height: 880px;
    }
}

@media only screen and (min-width: 680px) {
    .YelloCaseStudyContainer.FriendsTag {
        height: 1080px;
    }
}

@media only screen and (min-width: 880px) {
    .YelloCaseStudyContainer.FriendsTag {
        height: 1180px;
    }
}

@media only screen and (min-width: 980px) {
    .YelloCaseStudyContainer.FriendsTag {
        height: 1280px;
    }
}

@media only screen and (min-width: 1040px) {
    .YelloCaseStudyContainer.FriendsTag {
        height: 900px;
    }
}

@media only screen and (min-width: 1120px) {
    .YelloCaseStudyContainer.FriendsTag {
        height: 1040px;
    }
}

.YelloCaseStudyContainer.PhotoAlbumTag {
    height: 820px;
}

@media only screen and (min-width: 540px) {
    .YelloCaseStudyContainer.PhotoAlbumTag {
        height: 940px;
    }
}

@media only screen and (min-width: 680px) {
    .YelloCaseStudyContainer.PhotoAlbumTag {
        height: 1200px;
    }
}

@media only screen and (min-width: 880px) {
    .YelloCaseStudyContainer.PhotoAlbumTag {
        height: 1260px;
    }
}

@media only screen and (min-width: 980px) {
    .YelloCaseStudyContainer.PhotoAlbumTag {
        height: 1360px;
    }
}

@media only screen and (min-width: 1040px) {
    .YelloCaseStudyContainer.PhotoAlbumTag {
        height: 1040px;
    }
}

@media only screen and (min-width: 1120px) {
    .YelloCaseStudyContainer.PhotoAlbumTag {
        height: 1120px;
    }
}

.YelloCaseStudyContainer.ProfileTag {
    height: 680px;
}

@media only screen and (min-width: 540px) {
    .YelloCaseStudyContainer.ProfileTag {
        height: 800px;
    }
}

@media only screen and (min-width: 680px) {
    .YelloCaseStudyContainer.ProfileTag {
        height: 1000px;
    }
}

@media only screen and (min-width: 880px) {
    .YelloCaseStudyContainer.ProfileTag {
        height: 1180px;
    }
}

@media only screen and (min-width: 980px) {
    .YelloCaseStudyContainer.ProfileTag {
        height: 1260px;
    }
}

@media only screen and (min-width: 1040px) {
    .YelloCaseStudyContainer.ProfileTag {
        height: 820px;
    }
}

@media only screen and (min-width: 1120px) {
    .YelloCaseStudyContainer.ProfileTag {
        height: 1000px;
    }
}

.YelloCaseStudyContainer.StoryboardTag {
    height: 480px;
}

@media only screen and (min-width: 540px) {
    .YelloCaseStudyContainer.StoryboardTag {
        height: 580px;
    }
}

@media only screen and (min-width: 680px) {
    .YelloCaseStudyContainer.StoryboardTag {
        height: 740px;
    }
}

@media only screen and (min-width: 880px) {
    .YelloCaseStudyContainer.StoryboardTag {
        height: 840px;
    }
}

@media only screen and (min-width: 980px) {
    .YelloCaseStudyContainer.StoryboardTag {
        height: 940px;
    }
}

@media only screen and (min-width: 1040px) {
    .YelloCaseStudyContainer.StoryboardTag {
        height: 540px;
    }
}

@media only screen and (min-width: 1120px) {
    .YelloCaseStudyContainer.StoryboardTag {
        height: 700px;
    }
}

.YelloCaseStudyContainer.ReactionTag {
    height: 380px;
}

@media only screen and (min-width: 540px) {
    .YelloCaseStudyContainer.ReactionTag {
        height: 580px;
    }
}

@media only screen and (min-width: 680px) {
    .YelloCaseStudyContainer.ReactionTag {
        height: 640px;
    }
}

@media only screen and (min-width: 880px) {
    .YelloCaseStudyContainer.ReactionTag {
        height: 740px;
    }
}

@media only screen and (min-width: 980px) {
    .YelloCaseStudyContainer.ReactionTag {
        height: 840px;
    }
}

@media only screen and (min-width: 1040px) {
    .YelloCaseStudyContainer.ReactionTag {
        height: 480px;
    }
}

@media only screen and (min-width: 1120px) {
    .YelloCaseStudyContainer.ReactionTag {
        height: 600px;
    }
}

.YelloCaseStudyContainer.ConceptTextTag {
    height: 395px;
}

@media only screen and (min-width: 720px) {
    .YelloCaseStudyContainer.ConceptTextTag {
        height: 565px;
    }
}

.YelloCaseStudyContainer.ConceptImageTag {
    height: 345px;
}

@media only screen and (min-width: 720px) {
    .YelloCaseStudyContainer.ConceptImageTag {
        height: 595px;
    }
}

.YelloCaseStudyContainer.IconographyTextTag {
    height: 295px;
}

@media only screen and (min-width: 720px) {
    .YelloCaseStudyContainer.IconographyTextTag {
        height: 445px;
    }
}

.YelloCaseStudyContainer.LaunchTextTag {
    height: 295px;
}

@media only screen and (min-width: 720px) {
    .YelloCaseStudyContainer.LaunchTextTag {
        height: 445px;
    }
}
