/**
 * Styles.css
 *
 * Created by Dennis Gecaj on 12/28/17
 *
 * @desc The stylesheet for the film component.
 * @author Dennis Gecaj
 * @copyright © Yello Lab Inc. All rights reserved.
 * @version 1.0
 */

.FocusbaseFilmPage.ActionButton {
    position: absolute;

}

.FocusbaseFilmPage.CenterWrapperTag {
    margin: 25px auto 50px auto;
    padding: 20px;
    overflow: visible;
    max-width: 1200px;
    min-width: 280px;
}

.FocusbaseFilmPage.PlayerWrapperTag {
    margin: calc(50vh - (50% * 0.5625) - 60px) auto 50px auto;
    max-width: 1200px;
    max-height: 675px;
    width: calc(100% - 40px);
    height: calc((100vw - 40px) * 0.5625);
    overflow: hidden;
    transform: translateZ(0px);
}

@media only screen and (min-width: 900px) {
    .FocusbaseFilmPage.PlayerWrapperTag {
        margin-top: calc(50vh - 337.5px - 60px);
    }
}

@media only screen and (max-height: 400px) {
    .FocusbaseFilmPage.PlayerWrapperTag {
        margin-top: 0;
    }
}
