/**
 * Fonts.css
 *
 * Created by Dennis Gecaj on 9/20/19
 *
 * @desc The stylesheet for all fonts.
 * @author Dennis Gecaj
 * @copyright © Dennis Gecaj. All rights reserved.
 * @version 1.0
 */

.TitleLargeFont {
    font: 22px "objektiv-mk2", sans-serif;
    font-weight: 700;
}

@media only screen and (min-width: 720px) {
    .TitleLargeFont {
        font: 36px "objektiv-mk2", sans-serif;
        font-weight: 700;
    }
}

.TitleSmallFont {
    font: 20px "objektiv-mk2", sans-serif;
    font-weight: 700;
}

@media only screen and (min-width: 720px) {
    .TitleSmallFont {
        font: 28px "objektiv-mk2", sans-serif;
        font-weight: 700;
    }
}

.HeaderFont {
    font: 18px "objektiv-mk2", sans-serif;
    font-weight: 700;
}

@media only screen and (min-width: 720px) {
    .HeaderFont {
        font: 24px "objektiv-mk2", sans-serif;
        font-weight: 700;
    }
}

.SubtitleSmallFont {
    font: 12px "objektiv-mk2", sans-serif;
    font-weight: 600;
}

@media only screen and (min-width: 720px) {
    .SubtitleSmallFont {
        font: 15px "objektiv-mk2", sans-serif;
        font-weight: 600;
    }
}

.HashtagFont {
    font: 10px "objektiv-mk2", sans-serif;
    font-weight: 600;
}

@media only screen and (min-width: 720px) {
    .HashtagFont {
        font: 12px "objektiv-mk2", sans-serif;
        font-weight: 600;
    }
}

.BodyFont {
    font: 12px "objektiv-mk2", sans-serif;
    font-weight: 500;
    line-height: 22px;
}

@media only screen and (min-width: 720px) {
    .BodyFont {
        font: 15px "objektiv-mk2", sans-serif;
        font-weight: 500;
        line-height: 28px;
    }
}

.ActionFont {
    font: 12px "objektiv-mk2", sans-serif;
    font-weight: 700;
}

@media only screen and (min-width: 720px) {
    .ActionFont {
        font: 15px "objektiv-mk2", sans-serif;
        font-weight: 700;
    }
}

.FooterFont {
    font: 10px "objektiv-mk2", sans-serif;
    font-weight: 500;
}

@media only screen and (min-width: 720px) {
    .FooterFont {
        font: 12px "objektiv-mk2", sans-serif;
        font-weight: 500;
    }
}

.QuoteFont {
    font: 22px "objektiv-mk2", sans-serif;
    font-style: italic;
    font-weight: 500;
}

@media only screen and (min-width: 720px) {
    .QuoteFont {
        font: 28px "objektiv-mk2", sans-serif;
        font-style: italic;
        font-weight: 500;
    }
}

.QuotationFont {
    font: 100px "bressay-display", serif;
    font-weight: 700;
}

@media only screen and (min-width: 720px) {
    .QuotationFont {
        font: 140px "bressay-display", serif;
        font-weight: 700;
    }
}
