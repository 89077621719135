.MosaicImageCardComponent.CardTag {
    width: 100%;
    height: 335px;
}

@media only screen and (min-width: 720px) {
    .MosaicImageCardComponent.CardTag {
        height: 595px;
    }
}

.MosaicImageCardComponent.FirstImage {
    position: absolute;
    width: calc(50% - 12.5px);
    height: calc(50% - 12.5px);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    top: 0;
    left: 0;
}

.MosaicImageCardComponent.SecondImage {
    position: absolute;
    width: calc(50% - 12.5px);
    height: calc(50% - 12.5px);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    top: 0;
    right: 0;
}

.MosaicImageCardComponent.ThirdImage {
    position: absolute;
    width: calc(50% - 12.5px);
    height: calc(50% - 12.5px);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    bottom: 0;
    left: 0;
}

.MosaicImageCardComponent.FourthImage {
    position: absolute;
    width: calc(50% - 12.5px);
    height: calc(50% - 12.5px);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    bottom: 0;
    right: 0;
}
